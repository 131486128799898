import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setUser } from '../../features/AuthSlice';
import { encryptAndStore } from '../../Utils/Encryption';

const SigninSuccess = () => {
    console.log('sign in succes page');
    
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const hasProcessed = useRef(false); // Track if the flow has already been processed

    // Utility to parse cookies into an object
    const parseCookies = () => {
        const cookies = document.cookie ? document.cookie.split('; ') : [];
        const cookieObj = {};
        cookies.forEach((cookie) => {
            const [key, value] = cookie.split('=');
            cookieObj[key] = decodeURIComponent(value || '');
        });
        return cookieObj;
    };

    useEffect(() => {
        if (hasProcessed.current) return; // Skip if already processed
        hasProcessed.current = true; // Mark as processed

        try {
            const cookies = parseCookies();
            console.log('Cookies:', cookies); // Log cookies to check their values

            if (cookies.user) {
                let userData = cookies.user;
                
                // Remove the `j:` prefix from the cookie data
                if (userData.startsWith('j:')) {
                    userData = userData.slice(2); // Remove the first 2 characters (j:)
                }
                
                // Attempt to parse the cleaned-up user data as JSON
                let user;
                try {
                    user = JSON.parse(userData);
                    console.log(user, 'user');
                } catch (e) {
                    console.error('Failed to parse cookie data as JSON:', userData);
                    navigate('/signin');
                    return;
                }

                if (user && user.id && user.name) {
                    encryptAndStore(user, "user");
                    dispatch(setUser(user));
                    document.cookie = 'user=; max-age=0; path=/;'; // Delete the cookie
                    navigate('/'); // Redirect to the home page
                } else {
                    console.error('Invalid user data');
                    navigate('/signin');
                }
            } else {
                console.error('No user cookie found');
                navigate('/signin');
            }
        } catch (error) {
            console.error('Error processing user login:', error);
            navigate('/signin');
        }
    }, [dispatch, navigate]);

    return <div>Redirecting...</div>;
};

export default SigninSuccess;
