import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchFavorites, updateFavoriteWorker } from "../../features/favoritesSlice";
import male from "../../Assets/Images/male.png";
import female from "../../Assets/Images/female.jpg";
import Spinner from "../../Components/Spinner/Spinner";
import { Heart, WindIcon } from "lucide-react";
import CustomPagination from "../../Components/Pagination/Pagination";
import "../WorkersPerSkill/WorkersPerSkill.css"
import { Tooltip } from "react-tooltip";
import toast from "react-hot-toast";

const FavoriteWorkers = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [pageNum, setPageNum] = useState(1);
  const itemsPerPage = 20;
  const currentPage = pageNum;
  const { user } = useSelector((store) => store.auth);
  const { favoriteWorkers, totalFavoriteWorkers } = useSelector((store) => store.favorites);

  console.log(favoriteWorkers, 'favoriteWorkers');


  useEffect(() => {
    if (user) {
      setIsLoading(true);
      dispatch(fetchFavorites(currentPage))
        .then(() => setIsLoading(false))
        .catch(() => setIsLoading(false));
    }
  }, [user, dispatch, currentPage]);


  const handleWorkerClick = (slug) => {
    const params = new URLSearchParams();
    params.set('id', slug);
    navigate(`/worker?${params.toString()}`);
  };

  const getWorkerImage = (profileImg) => {
    if (profileImg && profileImg?.trim()) {
      return profileImg;
    } else {
      return male;
    }

  };

  const capitalizeName = (name) => {
    return name?.split(" ")?.map((part) => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase()).join(" ");
  };

  const setCurrentPage = (page) => {
    setPageNum(page);
  };

  const addToFavorite = (workerId) => {
    const updatedFavorites = user.favoriteWorkers.includes(workerId)
      ? user.favoriteWorkers.filter((id) => id !== workerId) // Remove from favorites
      : [...user.favoriteWorkers, workerId]; // Add to favorites

    dispatch(updateFavoriteWorker(workerId))
      .then((result) => {
        console.log(result, "result");
        dispatch(fetchFavorites(currentPage)); // Ensure data is in sync
        toast.success(`${result.payload.message}`);
      })
      .catch((error) => {
        console.error("Error updating favorites:", error);
        toast.error("Failed to update favorites");
        // Revert on failure
        dispatch({ type: "auth/updateUserFavorites", payload: user.favoriteWorkers });
      });
  };


  const totalPages = Math.ceil(totalFavoriteWorkers / itemsPerPage);

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="site_padding favorite-workers-page">
          {!user ? (
            <h5>Please Sign in to View Your Favorite Workers</h5>
          ) : (
            <>
              <h5>Total active favorite workers: {totalFavoriteWorkers}</h5>
              <div className="worker-list">
                {favoriteWorkers?.map((fd) => {
                  return (
                    <div key={fd._id}>
                      <div className="worker-item box_shadow">
                        <div className="worker-image cursor-pointer">
                          <img
                            src={getWorkerImage(fd?.profileImg)}
                            alt={capitalizeName(fd.name)}
                            onClick={() => handleWorkerClick(fd.slug)}
                          />
                        </div>
                        <div className="worker-details">
                          <Heart
                            size={16}
                            data-tooltip-id={`tooltip-${fd._id}`}
                            // data-tooltip-content={isFavorite ? "Remove from Favorites" : "Add to Favorites"}
                            className={`favIcon favoriteIcon`}
                            onClick={() => addToFavorite(fd._id)}
                          />
                          <Tooltip id={`tooltip-${fd._id}`} place="top" effect="solid" />
                          <h5 onClick={() => handleWorkerClick(fd.slug)} className="cursor-pointer hover:underline">
                            {capitalizeName(fd.name)}
                          </h5>


                          <p className="worker-skill">
                            {Array.isArray(fd.skill) && fd.skill.length > 0
                              ? fd.skill.join(", ")
                              : "Skills not available"}
                          </p>


                          <p className="worker-city">
                            {fd.city || "City not available"}
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>

              {totalPages > 1 && (
                <CustomPagination
                  currentPage={pageNum}
                  totalPages={totalPages}
                  onPageChange={setCurrentPage}
                  maxVisibleButtons={4}
                />
              )}
            </>
          )}
        </div>
      )}
    </>
  );
};

export default FavoriteWorkers;
