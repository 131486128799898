import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Space, Divider, ConfigProvider } from 'antd';
import yme from "../../Assets/Images/yme.png";
import barcodeForiWork from "../../Assets/Images/barcodeForiWork.jpg";
import foriMazdoori from "../../Assets/Images/ForiMazdoori.png";
import ForiLogo from "../../Assets/Images/fori Logo.jpg";
import imagesomalia from "../../Assets/Images/imagesomalia.jpg";
import './DonateSomalia.css';
import { saveDonorInfoAndStartCheckout } from '../../features/DonationSomalia';
import { getPaymentStatus } from '../../features/DonationSomalia';
import { useDispatch } from 'react-redux';
import toast from 'react-hot-toast';

const DonateClean = () => {
    const dispatch = useDispatch();
    const [selectedAmount, setSelectedAmount] = useState('');
    const [customAmount, setCustomAmount] = useState('');
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const NOK_RATE = 11.3; // Example rate: 1 USD = 10.5 NOK

    // Add this helper function to format NOK amounts
    const formatNOK = (amount) => {
        return `NOK ${amount.toLocaleString('nb-NO')}`;  // Norwegian locale formatting
    };

    const donationOptions = [
        { people: "Empower an Individual", amount: 10, nokAmount: 10 * NOK_RATE },
        { people: "Empower 10 Individuals", amount: 100, nokAmount: 100 * NOK_RATE },
        { people: "Empower 100 Individuals", amount: 1000, nokAmount: 1000 * NOK_RATE },
    ];


    useEffect(() => {
        getPaymentStatus();
        console.log('Payment Status');
    }, []);

    const validateCustomAmount = (amount) => {
        const numAmount = Number(amount);
        return numAmount > 0 && numAmount <= 10000;
    };

    const handleCustomAmountChange = (e) => {
        const value = e.target.value;
        setSelectedAmount('custom');
        setCustomAmount(value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null);

        if (!selectedAmount) {
            setError('Please select a donation amount');
            return;
        }

        if (selectedAmount === 'custom') {
            if (!customAmount) {
                setError('Please enter a custom amount');
                return;
            }
            if (!validateCustomAmount(customAmount)) {
                setError('Please enter a valid amount between $1 and $10,000');
                return;
            }
        }

        setIsLoading(true);

        try {
            const donationData = {
                name: fullName.trim(),
                email: email.trim().toLowerCase(),
                phone: phone.trim(),
                donationRecipients: selectedAmount === 'custom'
                    ? 'custom'
                    : donationOptions.find(opt => opt.amount.toString() === selectedAmount)?.people.toString(),
                donationAmount: selectedAmount === 'custom' ? parseInt(customAmount) : parseInt(selectedAmount)
            };

            dispatch(saveDonorInfoAndStartCheckout(donationData))
                .then((result) => {
                    if (result.payload.success && result.payload.url) {
                        window.location.href = result.payload.url;
                    } else {
                        toast.error('Invalid response from server');
                    }
                })
                .catch((error) => {
                    toast.error('Error in signup of the user: ' + error.message);
                });
        } catch (err) {
            setError(err.message || 'Failed to process donation. Please try again.');
            console.error('Donation error:', err);
        } finally {
            setIsLoading(false);
        }
    };

    // useEffect(() => {
    //     const root = document?.querySelector('#root');
    //     if (root) {
    //         root?.querySelector('.Bannerheader')?.style.setProperty('display', 'none');
    //         root?.querySelector('.Footer')?.style.setProperty('display', 'none');
    //         root?.querySelector('.Mob_Navbar')?.style.setProperty('display', 'none');
    //     }
    // }, []);

    return (
        <div>
            <Helmet prioritizeSeoTags>
                {/* Basic Tags */}
                <title>Season Fundraiser for Somalia | Fori Work</title>
                <meta name="description" content="Empower Somalian communities this season by supporting their digital inclusion in Fori." />
                <link rel="canonical" href="https://work.fori.co/donate" />

                {/* Open Graph / Facebook */}
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://work.fori.co/donate" />
                <meta property="og:title" content="Season Fundraiser for Somalia | Fori Work" />
                <meta property="og:description" content="Empower Somalian communities this season by supporting their digital inclusion in Fori." />
                <meta property="og:image" content="https://work.fori.co/donate.jpg" />
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="785" />
                <meta property="og:image:type" content="image/png" />
                <meta property="og:image:alt" content="Somalia Fundraiser Campaign" />
                <meta property="og:site_name" content="Fori Work" />

                {/* Twitter */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:url" content="https://work.fori.co/donate" />
                <meta name="twitter:title" content="Season Fundraiser for Somalia | Fori Work" />
                <meta name="twitter:description" content="Empower Somalian communities this season by supporting their digital inclusion in Fori." />
                <meta name="twitter:image" content="https://work.fori.co/donate.jpg" />
                <meta name="twitter:image:alt" content="Somalia Fundraiser Campaign" />

                {/* WhatsApp */}
                <meta property="og:whatsapp:title" content="Season Fundraiser for Somalia | Fori Work" />
                <meta property="og:whatsapp:description" content="Empower Somalian communities this season by supporting their digital inclusion in Fori." />

                {/* Additional Social Media */}
                <meta property="og:locale" content="en_US" />
                <meta property="article:publisher" content="https://www.facebook.com/foriwork" /> {/* If you have a Facebook page */}

                {/* Social Share Preview Theme Color */}
                <meta name="theme-color" content="#12333a" />

                {/* Additional Mobile Sharing */}
                <meta name="format-detection" content="telephone=no" />
                <meta property="al:web:url" content="https://work.fori.co/donate" />

                {/* Rich Results / Structured Data - Optional but recommended */}
                <script type="application/ld+json">
                    {`
        {
          "@context": "https://schema.org",
          "@type": "DonateAction",
          "name": "Season Fundraiser for Somalia",
          "description": "Empower Somalian communities this season by supporting their digital inclusion in Fori.",
          "url": "https://work.fori.co/donate",
          "image": "https://work.fori.co/donate.jpg",
          "provider": {
            "@type": "Organization",
            "name": "Fori Work",
            "url": "https://work.fori.co"
          }
        }
      `}
                </script>
            </Helmet>

            <div className="CleanContainer">
                <div className="CleanWrapper">
                    <div className="CleanFormSection">
                        <div className="CleanLogoSection">
                            <div >
                                <div className="CleanLogoWrapper">
                                    <img src={yme} alt="YME Logo" className="CleanYmeLogo" />
                                </div>
                                <div className="CleanLogoWrapper">
                                    <img src={ForiLogo} alt="Fori Logo" />
                                </div>
                            </div>

                            <h1>Season Fundraiser for Somalia</h1>
                            <p className="CleanDescription">Empower Somalian communities this season by supporting their digital including in Fori.</p>
                        </div>

                        <form onSubmit={handleSubmit} className="CleanForm">


                            <div className="CleanDonationOptions">
                                {donationOptions?.map(({ people, amount, nokAmount }) => (
                                    <label
                                        key={amount}
                                        className={`CleanOption ${selectedAmount === amount.toString() ? 'selected' : ''}`}
                                    >
                                        <input
                                            type="radio"
                                            name="donation"
                                            value={amount}
                                            style={{ boxShadow: 'none' }}
                                            checked={selectedAmount === amount.toString()}
                                            onChange={(e) => setSelectedAmount(e.target.value)}
                                        />
                                        <div className="CleanOptionContent">
                                            <span className="CleanOptionAmount">
                                                ${amount} ({formatNOK(nokAmount)})
                                            </span>
                                            <span className="CleanOptionText">
                                                {people}
                                            </span>
                                        </div>
                                    </label>
                                ))}

                                <label className={`CleanOption ${selectedAmount === 'custom' ? 'selected' : ''}`}>
                                    <input
                                        type="radio"
                                        name="donation"
                                        value="custom"
                                        checked={selectedAmount === 'custom'}
                                        onChange={(e) => setSelectedAmount(e.target.value)}
                                    />
                                    <div className="CleanOptionContent">
                                        <input
                                            type="number"
                                            min="1"
                                            max="100000"
                                            style={{ boxShadow: 'none', border: 'none' }}
                                            value={customAmount}
                                            onChange={handleCustomAmountChange}
                                            className="CleanCustomInput"
                                            placeholder="Custom amount"
                                            onClick={() => setSelectedAmount('custom')}
                                        />
                                        {customAmount && (
                                            <span className="CleanOptionText">
                                                ({formatNOK(parseFloat(customAmount) * NOK_RATE)})
                                            </span>
                                        )}
                                    </div>
                                </label>
                            </div>

                            {error && <p className="CleanError" style={{ color: 'red' }}>{error}</p>}
                            <div className="CleanFormRow">
                                <div className="CleanInputGroup">
                                    <label>Full Name *</label>
                                    <input
                                        type="text"
                                        value={fullName}
                                        onChange={(e) => setFullName(e.target.value)}
                                        placeholder="Enter your name"
                                        className="CleanInput"
                                        style={{ boxShadow: 'none' }}
                                        required
                                    />
                                </div>

                                <div className="CleanInputGroup">
                                    <label>Email *</label>
                                    <input
                                        type="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        placeholder="yourname@example.com"
                                        className="CleanInput"
                                        style={{ boxShadow: 'none' }}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="CleanInputGroup">
                                <label>Phone Number</label>
                                <input
                                    type="tel"
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                    placeholder="(555) 555-5555"
                                    className="CleanInput"
                                    style={{ boxShadow: 'none' }}
                                />
                            </div>
                            <button
                                type="submit"
                                className="CleanSubmitButton"
                                disabled={isLoading}
                            >
                                {isLoading ? 'Processing...' : 'Contribute Now'}
                            </button>
                        </form>

                        {/* Partners Section */}
                        <div style={{
                            marginTop: '2rem',
                            padding: '1rem',
                            borderRadius: '8px',
                            width: '100%'
                        }}>
                            <div style={{
                                fontSize: '14px',
                                color: '#666',
                                textAlign: 'center',
                                margin: '1rem 0'
                            }}>
                                Partners & Collaborators
                            </div>
                            <div style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: '2rem',
                                flexWrap: 'wrap'
                            }}>
                                <ConfigProvider>
                                    <Space size={[30, 20]} wrap align="center">
                                        <img
                                            src={yme}
                                            alt="YME Logo"
                                            style={{ height: '40px', objectFit: 'contain' }}
                                        />
                                        <Divider type="vertical" />
                                        <img
                                            src={ForiLogo}
                                            alt="Fori Work Logo"
                                            style={{ height: '40px', objectFit: 'contain' }}
                                        />
                                        <Divider type="vertical" />
                                        <img
                                            src={foriMazdoori}
                                            alt="Fori Mazdoori Logo"
                                            style={{ height: '40px', objectFit: 'contain' }}
                                        />
                                        <Divider type="vertical" />
                                        <img
                                            src={barcodeForiWork}
                                            alt="Barcode Fori Work"
                                            style={{ height: '40px', objectFit: 'contain' }}
                                        />
                                    </Space>
                                </ConfigProvider>
                            </div>
                        </div>

                        {/* <p className="CleanPoweredBy">Powered by YME</p> */}
                    </div>

                    <div className="CleanImageSection">
                        <div className="CleanImageOverlay">
                            <img
                                src={imagesomalia}
                                alt="Somalia Donation"
                                className="CleanImage"
                            />
                            <div className="CleanImageContent">
                                <h2>Fundraiser Somalia</h2>
                                <p><strong> Your support today helps build a better tomorrow. Do you know 50% of world population is still offline? </strong></p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DonateClean;