import React, { useEffect, useState, useRef } from "react";
import Modal from "react-modal";
import "./HeaderAddressModal.css";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { setLocation, getLocationSuggestions, getReverseGeocode, clearSuggestions } from "../../features/locationSlice";
import { Loader2, X } from 'lucide-react';
import { updateAddress } from "../../features/AuthSlice";

Modal.setAppElement('#root');

const HeaderAddressModal = ({ open, onClose, address, setAddress }) => {
    const dispatch = useDispatch();
    const [permission, setPermission] = useState(null);
    const [inputValue, setInputValue] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [currentLocationLoading, setCurrentLocationLoading] = useState(false);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [searchTimeout, setSearchTimeout] = useState(null);

    // const storeLocation = useSelector((store) => store.location.location);
    const textualAddress = useSelector((store) => store.location.textual_address);
    // Add this right after your useSelector for suggestions
    const suggestions = useSelector((store) => store.location.suggestions);
    const suggestionRef = useRef(null);

    // Handle text address display
    useEffect(() => {
        if (textualAddress) {
            setAddress(textualAddress.formatted);
        } else {
            setAddress("Not Provided");
        }
        setIsLoading(false);
    }, [textualAddress, setAddress]);

    // Handle clicks outside suggestions
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (suggestionRef.current && !suggestionRef.current.contains(event.target)) {
                setShowSuggestions(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            dispatch(clearSuggestions());
        };
    }, [dispatch]);

    const handleInputChange = (e) => {
        const value = e.target.value;
        setInputValue(value);

        if (searchTimeout) {
            clearTimeout(searchTimeout);
        }

        const newTimeout = setTimeout(() => {
            if (value.trim()) {
                dispatch(getLocationSuggestions(value.trim()));
                setShowSuggestions(true);
            } else {
                dispatch(clearSuggestions());
                setShowSuggestions(false);
            }
        }, 300);

        setSearchTimeout(newTimeout);
    };

    const handleSuggestionClick = async (suggestion) => {
        const location = {
            latitude: parseFloat(suggestion.lat),
            longitude: parseFloat(suggestion.lon)
        };

        try {
            const reverseGeocodeResult = await dispatch(getReverseGeocode({
                lat: location.latitude,
                lon: location.longitude
            })).unwrap();

            if (reverseGeocodeResult.success) {
                dispatch(setLocation({
                    location,
                    address: reverseGeocodeResult.address
                }));

                dispatch(updateAddress({
                    lat: location.latitude,
                    lng: location.longitude,
                    city: reverseGeocodeResult.address.components._normalized_city,
                    textAddress: reverseGeocodeResult.address.formatted
                }));

                setInputValue(reverseGeocodeResult.address.formatted);
                dispatch(clearSuggestions());
                setShowSuggestions(false);
                toast.success('Location updated successfully');
                onClose();
            } else {
                toast.error(reverseGeocodeResult.message || 'Error updating location');
            }
        } catch (error) {
            toast.error('Error updating location');
        }
    };

    const handleLocationClick = async () => {
        if (navigator.geolocation) {
            try {
                setCurrentLocationLoading(true);
                const perm = await navigator.permissions.query({ name: 'geolocation' });
                setPermission(perm.state);

                if (perm.state === 'denied') {
                    toast.error("Location permission was denied.");
                    setCurrentLocationLoading(false);
                    return;
                }

                navigator.geolocation.getCurrentPosition(async (position) => {
                    const location = {
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude
                    };

                    try {
                        const reverseGeocodeResult = await dispatch(getReverseGeocode({
                            lat: location.latitude,
                            lon: location.longitude
                        })).unwrap();

                        if (reverseGeocodeResult.success) {
                            dispatch(setLocation({
                                location,
                                address: reverseGeocodeResult.address
                            }));

                            dispatch(updateAddress({
                                lat: location.latitude,
                                lng: location.longitude,
                                city: reverseGeocodeResult.address.components._normalized_city,
                                textAddress: reverseGeocodeResult.address.formatted
                            }));

                            toast.success('Location updated successfully');
                            onClose();
                        } else {
                            toast.error(reverseGeocodeResult.message || 'Error updating location');
                        }
                    } catch (error) {
                        toast.error('Error getting location details');
                    }
                    setCurrentLocationLoading(false);
                }, (error) => {
                    toast.error("Error accessing location");
                    setCurrentLocationLoading(false);
                });
            } catch (error) {
                setCurrentLocationLoading(false);
            }
        } else {
            toast.error("Geolocation is not supported by this browser.");
            setCurrentLocationLoading(false);
        }
    };

    if (!open) return null;

    return (
        <Modal
            isOpen={open}
            onRequestClose={onClose}
            contentLabel="Header Address"
            className="modal-content reveal"
            overlayClassName="modal-overlay"
        >
            <button
                onClick={onClose}
                className="absolute top-1.5 right-2.5 p-2 rounded-full hover:bg-gray-200 transition-colors"
            >
                <X size={20} />
            </button>

            <div>
                <h5 className="location_set_heading">
                    {isLoading ? 'Loading...' : `Current Set Location : ${address ? ` ${address}` : "Not Provided"}`}
                </h5>
                <div className="location_input_form" ref={suggestionRef}>
                    <input
                        type="text"
                        placeholder="Enter Your Address..."
                        value={inputValue}
                        onChange={handleInputChange}
                        onFocus={() => setShowSuggestions(true)}
                    />
                    {showSuggestions && suggestions?.length > 0 && (
                        <div className="suggestions-dropdown">
                            {suggestions.map((suggestion, index) => (
                                <div
                                    key={index}
                                    className="suggestion-item"
                                    onClick={() => handleSuggestionClick(suggestion)}
                                >
                                    {suggestion.display_name}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>

            <div className="or-container">
                <hr className="line" />
                <span className="or">OR</span>
                <hr className="line" />
            </div>

            <div className="parent_current_location">
                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="17" height="17" viewBox="0,0,256,256">
                    <g fill="#12333a" fillRule="nonzero" stroke="none" strokeWidth="1" strokeLinecap="butt" strokeLinejoin="miter" strokeMiterlimit="10" strokeDasharray="" strokeDashoffset="0" fontFamily="none" fontWeight="none" fontSize="none" textAnchor="none" style={{ mixBlendMode: "normal" }}>
                        <g transform="scale(8.53333,8.53333)">
                            <path d="M14.98438,0.98633c-0.55152,0.00862 -0.99193,0.46214 -0.98437,1.01367v1.05078c-5.8183,0.48383 -10.46786,5.13159 -10.95117,10.94922h-1.04883c-0.36064,-0.0051 -0.69608,0.18438 -0.87789,0.49587c-0.18181,0.3115 -0.18181,0.69676 0,1.00825c0.18181,0.3115 0.51725,0.50097 0.87789,0.49587h1.05078c0.48379,5.81775 5.13146,10.46543 10.94922,10.94922v1.05078c-0.0051,0.36064 0.18438,0.69608 0.49587,0.87789c0.3115,0.18181 0.69676,0.18181 1.00825,0c0.3115,-0.18181 0.50097,-0.51725 0.49587,-0.87789v-1.05078c5.81775,-0.48379 10.46543,-5.13146 10.94922,-10.94922h1.05078c0.36064,0.0051 0.69608,-0.18438 0.87789,-0.49587c0.18181,-0.3115 0.18181,-0.69676 0,-1.00825c-0.18181,-0.3115 -0.51725,-0.50097 -0.87789,-0.49587h-1.04883c-0.48331,-5.81763 -5.13287,-10.46539 -10.95117,-10.94922v-1.05078c0.0037,-0.2703 -0.10218,-0.53059 -0.29351,-0.72155c-0.19133,-0.19097 -0.45182,-0.29634 -0.72212,-0.29212zM14,5.04883v0.95117c-0.0051,0.36064 0.18438,0.69608 0.49587,0.87789c0.3115,0.18181 0.69676,0.18181 1.00825,0c0.3115,-0.18181 0.50097,-0.51725 0.49587,-0.87789v-0.95117c4.73323,0.46766 8.4818,4.21779 8.94922,8.95117h-0.94922c-0.36064,-0.0051 -0.69608,0.18438 -0.87789,0.49587c-0.18181,0.3115 -0.18181,0.69676 0,1.00825c0.18181,0.3115 0.51725,0.50097 0.87789,0.49587h0.95117c-0.46764,4.73295 -4.21822,8.48354 -8.95117,8.95117v-0.95117c0.0037,-0.2703 -0.10218,-0.53059 -0.29351,-0.72155c-0.19133,-0.19097 -0.45182,-0.29634 -0.72212,-0.29212c-0.55152,0.00862 -0.99193,0.46214 -0.98437,1.01367v0.95117c-4.73295,-0.46764 -8.48354,-4.21822 -8.95117,-8.95117h0.95117c0.36064,0.0051 0.69608,-0.18438 0.87789,-0.49587c0.18181,-0.3115 0.18181,-0.69676 0,-1.00825c-0.18181,-0.3115 -0.51725,-0.50097 -0.87789,-0.49587h-0.94922c0.46742,-4.73338 4.21599,-8.48351 8.94922,-8.95117zM15,11c-2.20914,0 -4,1.79086 -4,4c0,2.20914 1.79086,4 4,4c2.20914,0 4,-1.79086 4,-4c0,-2.20914 -1.79086,-4 -4,-4z"></path>
                        </g>
                    </g>
                </svg>

                <button
                    className="button_location"
                    onClick={handleLocationClick}
                    disabled={currentLocationLoading}
                >
                    {currentLocationLoading ? (
                        <>Getting Location <Loader2 className='loader_button' /></>
                    ) : (
                        "Use Your Current Location"
                    )}
                </button>
            </div>

            <div className="permission_denied">
                <h5>{permission === 'denied' ? 'Location permission was denied.' : ''}</h5>
            </div>
        </Modal>
    );
};

export default HeaderAddressModal;