import React, { useEffect, useState } from "react";
import "./MobileNav.css";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import Select from "react-select";
import { getAllSkills } from "../../features/AdminSlice";

const MobileNav = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentPage = window.location.pathname;

  const { user } = useSelector((store) => store.auth);
  const { allSkills } = useSelector((store) => store.admin);
  const [show, setShow] = useState(false);
  const [firstShow, setFirstShow] = useState(true);

  const handleRegisterClick = (e) => {
    showFunction();
    if (!user) {
      e.preventDefault();
      toast("Please sign in to register a worker");
    }
  };

  const showFunction = () => {
    setFirstShow(false);
    setShow(!show);
  };

  useEffect(() => {
    dispatch(getAllSkills());
  }, [dispatch]);

  const options = allSkills.map((title) => ({ value: title.name, label: title.name }));
  const selectOptions = options.map(option => ({ value: option.value, label: option.label }));
  const handleChange = (selectedOption) => {
    navigate(`/skill/${selectedOption}`);
    showFunction();
  };

  return (
    <div className="Mob_Navbar">
      <div className={show ? "Mob_Navbar_right" : firstShow === false && show === false ? "hidden_animation" : "hidee"}>
        <div className="select-input2">
          <Select
            value={selectOptions.find(option => option.value === allSkills.value)}
            onChange={(selectedOption) => handleChange(selectedOption.value)}
            options={selectOptions}
            placeholder="Search a Worker"
            styles={{
              container: (provided) => ({ ...provided, width: '100%' }),
              option: (provided) => ({ ...provided, fontSize: window.innerWidth <= 768 ? '14px' : '16px', letterSpacing: 'normal' }),
              placeholder: (provided) => ({ ...provided, fontSize: '14px' }),
              input: (provided) => ({ ...provided, fontSize: '14px' }),
            }}
          />
        </div>

        <NavLink onClick={showFunction} to="/">
          HOME
        </NavLink>
        <NavLink onClick={showFunction} to="/about">
          ABOUT
        </NavLink>
        <NavLink onClick={showFunction} to="/skills?page=1">
          SKILLS
        </NavLink>

        {user ? (
          <>
            <NavLink onClick={showFunction} to="/Bookings">
              DASHBOARD
            </NavLink>
            <NavLink onClick={showFunction} to="/Bookings/createBooking">BOOK WORKER</NavLink>
            <NavLink onClick={showFunction} to="/Bookings/createBroadcastTask">BROADCAST TASK</NavLink>
            {user.email === "ceo@fori.co" && (
              <div>
                <NavLink onClick={showFunction} to="/Dashboard">DASHBOARD</NavLink>
              </div>
            )}
            <NavLink to="/register-as-worker" onClick={handleRegisterClick}>
              BECOME A WORKER
            </NavLink>
            <NavLink to="/addAWorker" onClick={handleRegisterClick}>
              ADD A WORKER
            </NavLink>
            <NavLink to="/register-multiple-workers" onClick={handleRegisterClick}>
              ADD MULTIPLE WORKERS
            </NavLink>
          </>
        ) : (
          <NavLink to="/register-as-worker" onClick={handleRegisterClick}>
            REGISTER
          </NavLink>
        )}
      </div>
      {currentPage && (
        <div className={show ? "cross burger" : "burger"} onClick={showFunction}>
          <div></div>
          <div></div>
        </div>
      )}
    </div>
  );
};

export default MobileNav;