import React, { useState, useEffect } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import {
   User,
   Briefcase,
   CreditCard,
   Menu as MenuIcon,
   ChevronRight,
   ChevronDown,
   FileText,
   Users,
   PlusCircle,
   Megaphone,
   Share2,
   Clock
} from 'lucide-react';
import { useDispatch, useSelector } from 'react-redux';
import { getEmployerBroadcastJob } from '../../features/Booking';
import "../Dashboard/Dashboard.css";

const Sidebar = ({ isSidebarCollapsed, isActive }) => {
   const location = useLocation();
   const dispatch = useDispatch();
   const user = useSelector((store) => store.auth.user);
   const { employerBroadcastJob } = useSelector((store) => store.booking);


   const [workerSectionExpanded, setWorkerSectionExpanded] = useState(
      location.pathname.includes('availableBookings') ||
      location.pathname.includes('getBroadcastJobs') ||
      location.pathname.includes('broadcastJob')
   );

   const [employerSectionExpanded, setEmployerSectionExpanded] = useState(
      location.pathname.includes('createBooking') ||
      location.pathname.includes('createBroadcastJob') ||
      location.pathname.includes('bookingsAsEmployer') ||
      location.pathname.includes('manageApplicants')
   );


   useEffect(() => {
      dispatch(getEmployerBroadcastJob());
   }, [dispatch]);


   useEffect(() => {
      if (window.innerWidth < 768) {
         setWorkerSectionExpanded(false);
         setEmployerSectionExpanded(false);
      }
   }, [location.pathname]);

   if (!user) return null;

   return (
      <div className={`sidebar ${isSidebarCollapsed ? 'hidden' : 'visible'}`}>
         <div className="sidebar-content">
            <nav>
               <ul>
                  {/* Worker Profile */}
                  {user.workerId && (
                     <li className={isActive('profile') ? 'active' : ''}>
                        <Link to="/Bookings/profile">
                           <User size={18} />
                           <strong>My Profile</strong>
                        </Link>
                     </li>
                  )}

                  {/* Worker Section */}
                  {user.workerId && (
                     <li className="collapsible-section">
                        <div
                           className="section-header"
                           onClick={() => setWorkerSectionExpanded(!workerSectionExpanded)}
                        >
                           {workerSectionExpanded ? <ChevronDown size={18} /> : <ChevronRight size={18} />}
                           <strong>As a Worker</strong>
                        </div>
                        {workerSectionExpanded && (
                           <ul className="subsection-list">
                              <li className={isActive('availableBookings') ? 'active' : ''}>
                                 <Link to="/Bookings/availableBookings">
                                    <FileText size={18} />
                                    <span>Find Available Jobs</span>
                                 </Link>
                              </li>
                              <li className={isActive('getBroadcastJobs') ? 'active' : ''}>
                                 <Link to="/Bookings/getBroadcastJobs">
                                    <Clock size={18} />
                                    <span>My Applied Jobs</span>
                                 </Link>
                              </li>
                              <li className={isActive('broadcastJob') ? 'active' : ''}>
                                 <Link to="/Bookings/broadcastJob">
                                    <Megaphone size={18} />
                                    <span>My Tasks</span>
                                 </Link>
                              </li>
                           </ul>
                        )}
                     </li>
                  )}

                  {/* Employer Section */}
                  <li className="collapsible-section">
                     <div
                        className="section-header"
                        onClick={() => setEmployerSectionExpanded(!employerSectionExpanded)}
                     >
                        {employerSectionExpanded ? <ChevronDown size={18} /> : <ChevronRight size={18} />}
                        <strong>As an Employer</strong>
                     </div>
                     {employerSectionExpanded && (
                        <ul className="subsection-list">
                           {/* <li className={isActive('createBooking') ? 'active' : ''}>
                              <Link to="/Bookings/createBooking">
                                 <PlusCircle size={18} />
                                 <span>Quick Hire</span>
                              </Link>
                           </li> */}
                           <li className={isActive('createBroadcastJob') ? 'active' : ''}>
                              <Link to="/Bookings/createBroadcastJob">
                                 <Share2 size={18} />
                                 <span>Post Job Opening</span>
                              </Link>
                           </li>
                           <li className={isActive('bookingsAsEmployer') ? 'active' : ''}>
                              <Link to="/Bookings/bookingsAsEmployer">
                                 <Briefcase size={18} />
                                 <span>My Posted Jobs</span>
                              </Link>
                           </li>
                           {employerBroadcastJob?.broadcastJobs?.length > 0 && (
                              <li className={isActive('manageApplicants') ? 'active' : ''}>
                                 <Link to="/Bookings/manageApplicants">
                                    <Users size={18} />
                                    <span>Manage Applicants</span>
                                 </Link>
                              </li>
                           )}
                        </ul>
                     )}
                  </li>

                  {/* Payment Methods */}
                  <li className={isActive('allPaymentMethods') ? 'active' : ''}>
                     <Link to="/Bookings/allPaymentMethods">
                        <CreditCard size={18} />
                        <span>Payment Methods</span>
                     </Link>
                  </li>
               </ul>
            </nav>
         </div>
      </div>
   );
};

const Bookings = () => {
   const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
   const location = useLocation();


   useEffect(() => {
      const checkAndCollapseSidebar = () => {
         setIsSidebarCollapsed(window.innerWidth < 768);
      };

      checkAndCollapseSidebar();
      window.addEventListener('resize', checkAndCollapseSidebar);
      return () => window.removeEventListener('resize', checkAndCollapseSidebar);
   }, []);


   useEffect(() => {
      if (window.innerWidth < 768) {
         setIsSidebarCollapsed(true);
      }
   }, [location.pathname]);

   const isActive = (path) => location.pathname.includes(path);

   return (
      <div className="dashboard_container">
         <Sidebar
            isSidebarCollapsed={isSidebarCollapsed}
            isActive={isActive}
         />
         <div className="main_content_sidebar">
            <button
               className="toggle_sidebar_button"
               onClick={() => setIsSidebarCollapsed(!isSidebarCollapsed)}
               aria-label="Toggle Sidebar"
            >
               <MenuIcon size={24} />
            </button>
            <div className="main-content-wrapper">
               <Outlet />
            </div>
         </div>
      </div>
   );
};

export default Bookings;