import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-hot-toast";
import { encryptAndStore, decryptAndRetrieve } from "../Utils/Encryption";

// get user from local storage
const user = decryptAndRetrieve("user");
const initialState = {
  user: user ? user : null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: [],
  chat: [],
};

/////////////////////////////sign up user///////////////////////////////
export const signUpUser = createAsyncThunk(
  "auth/signUpUser",
  async (data, thunkAPI) => {
    try {
      const API_URL = `${process.env.REACT_APP_SERVER_URL}/auth/signUpUser`;
      const response = await axios.post(API_URL, data);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);




/////////////////////////////verify user///////////////////////////////
export const verifyUser = createAsyncThunk(
  "auth/verifyUser",
  async ({ verificationCode, token }, thunkAPI) => {
    try {
      const API_URL = `${process.env.REACT_APP_SERVER_URL}/auth/verifyUser`;
      const response = await axios.post(API_URL, { verificationCode, token });
      if (response.data.success === true) {
        encryptAndStore(response.data.user, "user");
      }
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);





/////////////////////////////sign in user///////////////////////////////
export const signInUser = createAsyncThunk(
  "auth/signInUser",
  async (data, thunkAPI) => {
    try {
      const API_URL = `${process.env.REACT_APP_SERVER_URL}/auth/signInUser`;
      const response = await axios.post(API_URL, data);

      if (response.data.success === true && response.data.user) {
        encryptAndStore(response.data.user, "user");
      }
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);





//////////////////////Resend Verification code///////////////////////////
export const resendVerificationCode = createAsyncThunk(
  "auth/resendVerificationCode",
  async ({ data }, thunkAPI) => {
    try {
      const API_URL = `${process.env.REACT_APP_SERVER_URL}/auth/resendVerificationCode`;
      const response = await axios.post(API_URL, data);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response?.data &&
          error.response?.data?.message) ||
        error.response?.data?.status ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);





/////////////////////////////Forgot Password///////////////////////////////
export const forgotPassword = createAsyncThunk(
  "auth/forgotPassword",
  async (data, thunkAPI) => {
    try {
      const API_URL = `${process.env.REACT_APP_SERVER_URL}/auth/forgotPassword`;
      const response = await axios.post(API_URL, data);

      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);





/////////////////////////////Reset Password///////////////////////////////
export const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async ({ password, token, SigninProcess }, thunkAPI) => {
    try {
      const API_URL = `${process.env.REACT_APP_SERVER_URL}/auth/resetPassword`;
      const response = await axios.post(API_URL, {
        password,
        token,
        SigninProcess,
      });
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);






///////////////////////////////Logout User///////////////////////////////
export const logout = createAsyncThunk("auth/logout", async (_, thunkAPI) => {
  try {
    localStorage.removeItem("user");
    thunkAPI.dispatch(resetAll());
  } catch (error) {
    return thunkAPI.rejectWithValue("Logout Unsuccessful");
  }
});





/////////////////////////////Get User///////////////////////////////
export const getUser = createAsyncThunk(
  "auth/getUser",
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
      const API_URL = `${process.env.REACT_APP_SERVER_URL}/auth/getUser`;
      const response = await axios.get(API_URL, config);

      if (response.data.success === true && response.data.user) {
        encryptAndStore(response.data.user, "user");
      }
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);


/////////////////////////////Contact///////////////////////////////
export const contact = createAsyncThunk(
  "auth/contact",
  async (data, thunkAPI) => {
    try {
      const API_URL = `${process.env.REACT_APP_SERVER_URL}/auth/contact`;
      const token = thunkAPI.getState().auth.user.token
      const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
      const response = await axios.post(API_URL, data, config);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

/////////////////////////////Get All Messages///////////////////////////////
export const getAllMessagesOfTheChat = createAsyncThunk(
  'auth/getAllMessagesOfTheChat',
  async (participants, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      if (!token) {
        throw new Error('No authentication token found');
      }

      if (!Array.isArray(participants) || participants.length !== 2) {
        throw new Error('Invalid participants format');
      }
      const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }, params: {
          participants: participants
        }
      };
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/auth/getAllMessagesOfTheChat`,
        config
      );


      return response.data;

    } catch (error) {
      const message =
        (error.response?.data?.message) ||
        error.message ||
        'Failed to fetch messages';

      return thunkAPI.rejectWithValue(message);
    }
  });


/////////////////////////////getAllChatsOfTheUser///////////////////////////////
export const getAllChatsOfTheUser = createAsyncThunk(
  'auth/getAllChatsOfTheUser',
  async (userId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      if (!token) {
        throw new Error('No authentication token found');
      }

      const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/auth/getAllChatsOfTheUser/${userId}`,
        config
      );

      return response.data;

    } catch (error) {
      const message =

        (error.response?.data?.message) ||
        error.message ||
        'Failed to fetch messages';

      return thunkAPI.rejectWithValue(message);
    }
  });


export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
    },
    resetAll: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
      state.user = null;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      ///////////////////////////Sign Up User////////////////////////////
      .addCase(signUpUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(signUpUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload.message;
        toast.success(action.payload.message);
      })
      .addCase(signUpUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.user = null;
        toast.error(action.payload);
      })
      ///////////////////////////Verify User////////////////////////////
      .addCase(verifyUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(verifyUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.user = action.payload.user;
        state.message = action.payload.message;
        toast.success(action.payload.message);
      })
      .addCase(verifyUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
      })
      ///////////////////////Sign In User/////////////////////////
      .addCase(signInUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(signInUser.fulfilled, (state, action) => {
        if (action.payload.success === true) {
          state.user = action.payload.user;
        }
        state.isLoading = false;
        state.isSuccess = true;
        toast.success(action.payload.message);
      })
      .addCase(signInUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.user = null;
        toast.error(action.payload);
      })
      /////////////Resend Verification code//////////////
      .addCase(resendVerificationCode.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(resendVerificationCode.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload.message;
        toast(action.payload.message);
      })
      .addCase(resendVerificationCode.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload.message
        toast.error(action.payload.message)
      })
      ///////////////////////Forgot Password/////////////////////////
      .addCase(forgotPassword.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        toast(action.payload.message);
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast(action.payload);
      })
      ///////////////////////Reset Password/////////////////////////
      .addCase(resetPassword.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.message = action.payload.message;
        toast(action.payload.message);
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        toast.error(action.payload);
      })
      ///////////////////////Logout User/////////////////////////
      .addCase(logout.fulfilled, (state) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = false;
        state.message = "";
        state.user = null;
      })
      ///////////////////////get User/////////////////////////
      .addCase(getUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        if (action.payload.success === true) {
          state.user = action.payload.user;
        }
        state.isLoading = false;
        state.isSuccess = true;
        // toast.success(action.payload.message)
      })
      .addCase(getUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.user = null;
        // toast.error(action.payload)
        // toast.error(action.payload)
      })
      ///////////////////////Contact/////////////////////////
      .addCase(contact.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(contact.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload.message;
        // toast.success(action.payload.message);
      })
      .addCase(contact.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        // toast.error(action.payload);
      })
      ///////////////////////Get All Messages/////////////////////////
      .addCase(getAllMessagesOfTheChat.pending, (state) => {
        state.isLoading = true;
      }
      )
      .addCase(getAllMessagesOfTheChat.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        // console.log(action.payload, "action.payload.messages")
        state.message = action.payload.chat.messages;
        // console.log(action.payload.chat.messages, "state.payload.chat.messages")
        // toast.success(action.payload.message);
      }
      )
      .addCase(getAllMessagesOfTheChat.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        // toast.error(action.payload);
      }
      )
      ///////////////////////Get All Chats/////////////////////////
      .addCase(getAllChatsOfTheUser.pending, (state) => {
        state.isLoading = true;
      }
      )
      .addCase(getAllChatsOfTheUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.chat = action.payload.chats;
        // toast.success(action.payload.message);
      }
      )
      .addCase(getAllChatsOfTheUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.chat = action.payload;
        // toast.error(action.payload);
      }
      );
  },
});

export const { reset, resetAll, setUser } = authSlice.actions;
export default authSlice.reducer;
