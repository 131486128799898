import { Link, NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import { getUser, logout } from "../../features/AuthSlice";
import { setKey, setLanguage, setRegion } from "react-geocode";
import { getAllChatsOfTheUser } from '../../features/AuthSlice';
import Select from "react-select";
import { useCallback, useEffect, useRef, useState } from "react";
import { ChevronDown } from "lucide-react"; 
import axios from "axios";
import "./Header.css";

// Components
import HeaderAddressModal from "../HeaderAddressModal/HeaderAddressModal";

// Assets
import fori_logo from "../../Assets/Images/fori Logo.png";
import { otherIcons } from "../../Assets/Data/otherIcons";

// Actions
import { getAllSkills } from "../../features/AdminSlice";
import { setLocation } from "../../features/locationSlice";
import { getBookingsByWorkerID } from "../../features/Booking";
import NotificationPanel from "../TabbedNotificationPanel/TabbedNotificationPanel";

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const notificationRef = useRef(null);
  const dropdownRef = useRef(null);

  // Selectors
  const { user } = useSelector((store) => store.auth);
  const { allSkills } = useSelector((store) => store.admin);
  const { workerBookings } = useSelector((store) => store.booking);
  const storeLocation = useSelector((store) => store.location.location);
  const textualAddress = useSelector((store) => store.location.textual_address);
  const chat = useSelector((store) => store.auth.chat);

  // State
  const [notifications, setNotifications] = useState([]);
  const [showNotifications, setShowNotifications] = useState(false);
  const [isAddressModalOpen, setIsAddressModalOpen] = useState(false);
  const [isUserDropdownOpen, setIsUserDropdownOpen] = useState(false);
  const [address, setAddress] = useState("");
  const [addressUnderIcon, setAddressUnderIcon] = useState('');
  const [selectedOption, setSelectedOption] = useState("");


  // Initialize Geocode and fetch user data
  useEffect(() => {
    setKey(process.env.REACT_APP_GOOGLE_API_KEY);
    setLanguage("en");
    setRegion("us");
    dispatch(getUser());
    dispatch(getAllSkills());
  }, [dispatch]);

  // Fetch worker bookings
  const fetchBookings = useCallback(() => {
    if (user?.workerId) {
      dispatch(getBookingsByWorkerID(user.workerId));
    }
  }, [dispatch, user]);

  useEffect(() => {
    fetchBookings();
  }, [fetchBookings]);

  // Fetch chats
  useEffect(() => {
    if (user) {
      dispatch(getAllChatsOfTheUser(user.id));
    }
  }, [dispatch, user]);
  console.log("chat", chat);

  // Process notifications
  useEffect(() => {
    if (user?.workerId && workerBookings?.bookings) {
      const now = new Date();
      const sevenDaysAgo = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);

      const pendingBookings = workerBookings.bookings.filter(
        booking => booking.jobStatus === "pending" &&
          new Date(booking.bookingCreatedAt) > sevenDaysAgo
      );

      const newNotifications = pendingBookings.map(booking => ({
        id: booking._id,
        message: `New booking request for ${booking.skill}`,
        createdAt: new Date(booking.bookingCreatedAt)
      }));

      setNotifications(newNotifications.sort((a, b) => b.createdAt - a.createdAt));
    } else {
      setNotifications([]);
    }
  }, [user, workerBookings]);

  // Handle location
  useEffect(() => {
    const fetchLocation = async () => {
      if (Object.keys(storeLocation).length === 0 && Object.keys(textualAddress).length === 0) {
        if (!navigator.geolocation) {
          alert("Geolocation is not supported by this browser.");
          return;
        }

        navigator.geolocation.getCurrentPosition(async (position) => {
          const location = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          };

          try {
            const response = await axios.get(
              `https://api.opencagedata.com/geocode/v1/json?q=${location.latitude}+${location.longitude}&key=${process.env.REACT_APP_OPENCAGE_API_KEY}`
            );
            dispatch(setLocation({ location, address: response.data.results[0] }));
          } catch (error) {
            console.error('Error fetching location:', error);
          }
        });
      }
    };

    fetchLocation();
  }, [dispatch, storeLocation, textualAddress]);

  useEffect(() => {
    if (textualAddress) {
      const city = textualAddress.components._normalized_city ||
        textualAddress.components.district ||
        textualAddress.components.town;
      const country = textualAddress.components.country;
      setAddressUnderIcon(`${city}, ${country}`);
    }
  }, [textualAddress]);

  // Click outside handlers
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!notificationRef.current?.contains(event.target)) {
        setShowNotifications(false);
      }
      if (!dropdownRef.current?.contains(event.target)) {
        setIsUserDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  // Handlers
  const handleRegisterClick = () => {
    if (!user) {
      toast("Please sign in to register a worker");
    }
  };

  const handleSkillChange = (selectedOption) => {
    if (selectedOption) {
      navigate(`/skill/${selectedOption.value}`);
    }
  };

  const handleNotificationClick = () => {
    setShowNotifications(false);
    navigate('/bookings/availableBookings');
  };

  const handleLogout = () => {
    localStorage.removeItem('becomeAWorkerData');
    localStorage.removeItem('addAWorkerData');
    dispatch(logout()).then(() => navigate("/signin"));
    setIsUserDropdownOpen(false);
  };

  const getTimeAgo = (date) => {
    const now = new Date();
    const diffInMinutes = Math.floor((now - date) / (1000 * 60));

    if (diffInMinutes < 60) {
      return `${diffInMinutes} minute${diffInMinutes !== 1 ? 's' : ''} ago`;
    }
    if (diffInMinutes < 24 * 60) {
      const diffInHours = Math.floor(diffInMinutes / 60);
      return `${diffInHours} hour${diffInHours !== 1 ? 's' : ''} ago`;
    }
    const diffInDays = Math.floor(diffInMinutes / (24 * 60));
    return `${diffInDays} day${diffInDays !== 1 ? 's' : ''} ago`;
  };

  const pathName = window.location.pathname;
  const selectOptions = allSkills.map(option => ({
    value: option.name,
    label: option.name
  }));

  console.log("running");

  return (
    <div className="Bannerheader">
      <div className="header_left">
        <Link to="/">
          <img src={fori_logo} alt="Fori Logo" />
        </Link>
      </div>

      <div className="header_right flex_row">
        <NavLink 
          className={pathName === '/' ? "activeLink" : "non_active"} 
          to="/"
        >
          HOME
        </NavLink>

        <NavLink 
          className={pathName === '/about' ? "activeLink" : "non_active"} 
          to="/about"
        >
          ABOUT
        </NavLink>

        {user && (
          <div className="dropdown">
            <NavLink className="dropbtn">
              BOOKINGS
              <ChevronDown className="dropdown-arrow" size={16} />
            </NavLink>
            <div className="dropdown-content">
              {user.workerId && ( 
                <NavLink 
                
                to="/Bookings/profile"
              >
                MY PROFILE
              </NavLink>
              )}
              <NavLink 
                
                to="/Bookings"
              >
                DASHBOARD
              </NavLink>
              <NavLink 
                
                to="/Bookings/createBooking"
              >
                BOOK WORKER
              </NavLink>
              <NavLink 
                
                to="/Bookings/createBroadcastJob"
              >
                BROADCAST JOB
              </NavLink>
            </div>
          </div>
        )}

        <NavLink 
          className={pathName.startsWith('/skills') ? "activeLink" : "non_active"} 
          to="/skills"
        >
          SKILLS
        </NavLink>

        <div className="dropdown">
          <NavLink className="dropbtn">
            REGISTER
            <ChevronDown className="dropdown-arrow" size={16} />
          </NavLink>
          <div className="dropdown-content">
            <NavLink 
              to={user ? "/register-as-worker" : "/signin"} 
              onClick={handleRegisterClick}
            >
              BECOME A WORKER
            </NavLink>
            <NavLink 
              to={user ? "/addAWorker" : "/signin"} 
              onClick={handleRegisterClick}
            >
              ADD A WORKER
            </NavLink>
            <NavLink 
              to={user ? "/register-multiple-workers" : "/signin"} 
              onClick={handleRegisterClick}
            >
              ADD MULTIPLE WORKERS
            </NavLink>
          </div>
        </div>

        <div className="dropdown">
          <NavLink className="dropbtn">
            SEARCH
            <ChevronDown className="dropdown-arrow" size={16} />
          </NavLink>
          <div className="dropdown-content search-location">
            <div className="search_Worker_Field">
              <Select
                value={selectedOption}
                onChange={(option) => {
                  setSelectedOption(option);
                  handleSkillChange(option);
                }}
                options={selectOptions}
                placeholder="Search a Worker"
                styles={{ container: (provided) => ({ ...provided, width: '100%' }) }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="flex_row_center">

        {user && (
          <NotificationPanel
            notifications={notifications}
            chats={chat || []}  // This ensures we always pass an array
            handleNotificationClick={handleNotificationClick}
            getTimeAgo={getTimeAgo}
            user={user}
          />
        )}

        <div className="location location_heading">
          <button onClick={() => setIsAddressModalOpen(true)}>
            {otherIcons.locationIcon}
          </button>
          <h6 className="addressUnderIcon">
            {addressUnderIcon || 'Loading...'}
          </h6>

          {isAddressModalOpen && (
            <HeaderAddressModal
              open={isAddressModalOpen}
              onClose={() => setIsAddressModalOpen(false)}
              address={address}
              setAddress={setAddress}
            />
          )}
        </div>

        {user ? (
          <div className="avatar-container" ref={dropdownRef}>
            <button
              onClick={() => setIsUserDropdownOpen(!isUserDropdownOpen)}
              className="avatar-button bold"
            >
              {user.name?.charAt(0).toUpperCase() || ''}
            </button>

            {isUserDropdownOpen && (
              <div className="dropdownn box_shadow">
                <div className="user-info">
                  <p className="user-name">Name: {user.name}</p>
                  <p className="user-role">
                    Role: {user.workerId ? "User, Worker" : "User"}
                  </p>
                </div>
                <Link
                  to="/Bookings/allPaymentMethods"
                  className="dropdown-item"
                  onClick={() => setIsUserDropdownOpen(false)}
                >
                  Saved Payment Methods
                </Link>
                <hr />
                <button className="logOut_btn" onClick={handleLogout}>
                  {otherIcons.logOut} Logout
                </button>
              </div>
            )}
          </div>
        ) : (
          <Link className="btn" to="/signin">
            SIGN IN
          </Link>
        )}
      </div>
    </div>
  );
};

export default Header;