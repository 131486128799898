import React from 'react';
import './Pagination.css';

const CustomPagination = ({ currentPage, totalPages, onPageChange, maxVisibleButtons = 5 }) => {
    // If there's less than one page, don't render the pagination
    if (totalPages <= 1) {
        return null;
    }

    const getPageNumbers = () => {
        const pageNumbers = [];
        const leftOffset = Math.floor(maxVisibleButtons / 2);
        let start = Math.max(currentPage - leftOffset, 1);
        let end = Math.min(start + maxVisibleButtons - 1, totalPages);

        if (end - start + 1 < maxVisibleButtons) {
            start = Math.max(end - maxVisibleButtons + 1, 1);
        }

        if (start > 1) {
            pageNumbers.push(1);
            if (start > 2) pageNumbers.push('ellipsis');
        }

        for (let i = start; i <= end; i++) {
            pageNumbers.push(i);
        }

        if (end < totalPages) {
            if (end < totalPages - 1) pageNumbers.push('ellipsis');
            pageNumbers.push(totalPages);
        }

        return pageNumbers;
    };

    const handlePageChange = (newPage) => {
        if (newPage !== currentPage && newPage >= 1 && newPage <= totalPages) {
            onPageChange(newPage);
        }
    };

    const handleKeyDown = (event, pageNumber) => {
        if (event.key === 'Enter' || event.key === ' ') {
            event.preventDefault();
            handlePageChange(pageNumber);
        }
    };

    const renderPageButton = (number, index) => {
        if (number === 'ellipsis') {
            return (
                <span key={`ellipsis-${index}`} className="custom-pagination__ellipsis">
                    ...
                </span>
            );
        }

        const isVisible =
            number === 1 ||
            number === totalPages ||
            number === currentPage ||
            (number === currentPage - 1 && number > 1) ||
            (number === currentPage + 1 && number < totalPages);

        return (
            <button
                key={number}
                onClick={() => handlePageChange(number)}
                onKeyDown={(e) => handleKeyDown(e, number)}
                className={`custom-pagination__button ${number === currentPage ? 'custom-pagination__button--active' : ''} ${isVisible ? 'custom-pagination__button--visible' : ''
                    }`}
                aria-label={`Page ${number}`}
                aria-current={number === currentPage ? 'page' : undefined}
                disabled={number === currentPage}
            >
                {number}
            </button>
        );
    };

    return (
        <nav className="custom-pagination" aria-label="Pagination">
            <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className="custom-pagination__button custom-pagination__button--nav"
                aria-label="Previous page"
            >
                &lt;
            </button>
            <div className="custom-pagination__numbers">
                {getPageNumbers().map(renderPageButton)}
            </div>
            <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
                className="custom-pagination__button custom-pagination__button--nav"
                aria-label="Next page"
            >
                &gt;
            </button>
        </nav>
    );
};

export default CustomPagination;